import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index5',
    component: () => import('../views/5/index.vue')
  },
  // {
  //   path: '/index1',
  //   name: 'index1',
  //   component: () => import('../views/1/index.vue')
  // },
  // {
  //   path: '/index2',
  //   name: 'index2',
  //   component: () => import('../views/2/index.vue')
  // },
  // {
  //   path: '/index3',
  //   name: 'index3',
  //   component: () => import('../views/3/index.vue')
  // },
  // {
  //   path: '/index4',
  //   name: 'index4',
  //   component: () => import('../views/4/index.vue')
  // },
  {
    path: '/index5',
    name: 'index5',
    component: () => import('../views/5/index.vue')
  }
]
const router = new VueRouter({
  routes
})

export default router