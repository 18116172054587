<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  data() {
    return {
      index: 1
    }
  },
  mounted() {
    // this.timeFn()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.index = this.index%4+1;
        this.$router.push("/index"+this.index)
      }, 6000)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: auto;
}
</style>
